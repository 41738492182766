import { Controller } from 'stimulus';

export default class extends Controller {
  share() {
    if (typeof window.ga === 'function') {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'share_button',
        eventAction: 'click',
        eventLabel: this.data.get('sns-type'),
      });
    }
  }
}
