import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faTwitter, faLine } from '@fortawesome/free-brands-svg-icons';
import '../stylesheets/application.css';

library.add(faFacebook, faTwitter, faLine);
dom.watch();

const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(require.context('../initializers', true, /.js$/));
// requireAll(require.context("../src", true, /.js$/))
const application = Application.start();
const context = require.context('../controllers', true, /.js$/);
application.load(definitionsFromContext(context));
