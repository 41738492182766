import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (typeof window.ga === 'function') {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'article_detail',
        eventAction: 'view',
        eventLabel: this.data.get('article-type'),
      });
    }
  }
}
